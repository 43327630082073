<template>
    <!-- 경영공시 START -->
	<div class="content_container employment">
		<div class="content">
			<div class="banner">
				<h1>채용정보</h1>
			</div>
			
            <ListBoard whichBoard="employment" />
		</div>
	</div>
    <!-- 경영공시 END -->
</template>

<script>
import ListBoard from '@/components/ListBoard'

export default {
    name : "Employment",
	components : {
		ListBoard,
	},
}
</script>

